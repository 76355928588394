import React from "react"
import { FaCode, FaSketch, FaAndroid } from "react-icons/fa"
const services = [
  {
    id: 1,
    icon: <FaCode className="service-icon" />,
    title: "Web Development",
    text: `I'm currently learning and developing my skills in the web development space. Using and studying such technology stacks like React.js, JavaScript, Gatsby.js, HTML, CSS, and more.`,
  },
  {
    id: 2,
    icon: <FaSketch className="service-icon" />,
    title: "Web Design",
    text: `I'm also highly interested in web design by focusing on the fundamentals of CSS and other frameworks to create great looking web components.`,
  },
  {
    id: 3,
    icon: <FaAndroid className="service-icon" />,
    title: "App Design",
    text: `I have previous experience with android development using Java, but recently am investing into using React.js and other frameworks to create components for app design and development.`,
  },
]

export default services
