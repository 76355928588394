import React from "react"
import { Link } from "gatsby"
import Image from "../assets/images/hero-test.png"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import SocialLinks from '../constants/social_links'




const Hero = () => {

  
  return (
    <header className="hero">
      <section className="section-center hero-center">
        <article className="hero-info">
          <div>
            <div className="underline"></div>
            <h1>I'm Kenny</h1>
            <h4>Aspiring Software Engineer</h4>
            <Link to='/contact' className='btn'>
              contact me
            </Link>
            <SocialLinks />
          </div>
        </article>
        <StaticImage
          src='../assets/images/logo-new.svg' 
          alt='hero' 
          placeholder="blurred"
          className="hero-img-svg"
        />
        
      </section>
    </header>
  )
}



export default Hero
