import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Hero from "../components/Hero"
import Services from "../components/Services"
import Jobs from "../components/Jobs"
import Projects from "../components/Projects"
import Seo from "../components/Seo"


const IndexPage = ({data}) => {
  const { allContentfulProject: { nodes: projects }} = data
  

  return (
    <Layout>
      <Seo title="Home" description="This is the home page"/>
      <Hero />
      <Services />
      <Jobs />
      <Projects 
        projects={projects} 
        title='featured projects' 
        showLink
      />
    </Layout>

  )
}

export const query = graphql`
  {
    allContentfulProject(filter: {featured: {eq: true}}) {
      nodes {
        github
        id
        description {
          description
        }
        title
        url
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
        }
        stack {
          tags
        }
      }
    }
  }
`

export default IndexPage
